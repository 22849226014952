import { useEffect, useState } from "react";
import { ReqTheaterModalWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Req_Screen_URL, Req_Status_Update_URL, Req_Theaters_Details_URL } from "../API/Api";
import SeatSetting from "./SeatSettingModal";
import ScreenView from "./ScreenModal";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ModalReqTheater({showModal, setShowModal, theaterInfo, modalReload, setModalReload, setReload}) {

    const statuses = ['Pending','Confirm','Processing','Complete','Reject'];
    const [status, setStatus] = useState('Pending');
    const [statusDropdown, setStatusDropdown] = useState(false);
    const [theaterDetails, setTheaterDetails] = useState([]);
    const [screenDetails, setScreenDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [reason, setReason] = useState('');
    const [settingModalShow, setSettingModalShow] = useState(false);
    const [settingModalData, setSettingModalData] = useState({
        theaterName: '',
        screen: '',
        screen_id: '',
        capacity: ''
    });
    const [viewModalShow, setViewModalShow] = useState(false);
    const [viewModalData, setViewModalData] = useState({
        theaterName: '',
        screen: '',
        screen_id: '',
        capacity: ''
    });


    useEffect(() => {

        axios.get(`${Req_Theaters_Details_URL}?name=${theaterInfo}`)
        .then(res => {
            console.log(res);
            setStatus(res.data.theater.status);
            setTheaterDetails(res.data.theater);
            setUserDetails(res.data.theaterAdmin);
            setScreenDetails(res.data.screens);
        })
        .catch(err => {
            console.log(err);
        })
        
    }, [modalReload, theaterInfo]);

    const handleDropdown = () => {
        setStatusDropdown(!statusDropdown);
    };

    const handleReason = (e) => {
        setReason(e.target.value);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalReload(false);
    };

    const handleSettingModal = (name, screen, id, capacity) => {
        setSettingModalData({
            theaterName: name,
            screen: screen,
            screen_id: id,
            capacity: capacity
        });
        setSettingModalShow(true);
    };

    const handleViewModal = (name, screen, id, capacity) => {
        setViewModalData({
            theaterName: name,
            screen: screen,
            screen_id: id,
            capacity: capacity
        });
        setViewModalShow(true);
    };

    const handleUpdate = (e) => {
        e.preventDefault();

        if(status == "Pending" || status == "Confirm" || status == "Processing"){

            let inputs = {
                name: theaterInfo,
                status: status
            }
            
            axios.post(Req_Status_Update_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                let status = res.data.status;
                let statusType = res.data.statusType;

                if(status == "200"){
                    if(statusType == "Pending") {
                        toast.warn(`You have set ${theaterInfo}'s request to pending`, {
                            position: "top-right",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        });
                    } else if(statusType == "Confirm") {
                        toast.success(`You have confirmed ${theaterInfo}'s request`, {
                            position: "top-right",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        });
                    } else if(statusType == "Processing") {
                        toast.success(`You have processed ${theaterInfo}'s request`, {
                            position: "top-right",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        });
                    }
                    setReload(true);
                }
            })
            .catch(err => {
                console.log(err);
            })

        } else if(status == "Reject"){
            
            let inputs = {
                name: theaterInfo,
                status: status,
                reason: reason
            }

            axios.post(Req_Status_Update_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                let status = res.data.status;
                let statusType = res.data.statusType;

                if(status == "200"){
                    if(statusType == "Reject") {
                        toast.error(`You have rejected ${theaterInfo}'s request`, {
                            position: "top-right",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Bounce,
                        });
                    }
                    setReload(true);
                }
            })
            .catch(err => {
                console.log(err);
            })
        } else if(status == "Complete"){
            
            let inputs = {
                name: theaterInfo,
                status: status
            }

            axios.post(Req_Status_Update_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                let status = res.data.status;
                setSettingModalShow(false);
                
                if(status == "200"){
                    toast.success(`${theaterInfo} has been settled !!`, {
                        position: "top-right",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    handleCloseModal();
                    setReload(true);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(`Somthing Went Wrong !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })

        }
    };

    return(
        <>
            <ReqTheaterModalWrapper className={showModal ? 'active' : ''}>
                <div className={`view_box ${showModal ? 'active' : ''}`}>
                    <form className="box_inner" onSubmit={handleUpdate}>
                        <div className="theater_info_box">
                            <div className="info_box fullWidth">
                                <h5>Theater Name :</h5>
                                <p>{theaterDetails.name}</p>
                            </div>
                            <div className="info_box fullWidth">
                                <h5>GST No. :</h5>
                                <p>{theaterDetails.gst_no}</p>
                            </div>
                            <div className="info_box">
                                <h5>No. of Screens :</h5>
                                <p>{theaterDetails.screen_no}</p>
                            </div>
                            <div className="info_box">
                                <h5>City :</h5>
                                <p>{theaterDetails.city}</p>
                            </div>
                            <div className="info_box">
                                <h5>State :</h5>
                                <p>{theaterDetails.state}</p>
                            </div>
                        </div>
                        <div className="screen_info_box">
                            <h5>Screens :</h5>
                            {
                                screenDetails && screenDetails.map((screenDetail, index) => 
                                <div className="screen_info" key={index}>
                                    <p><span>Screen {index + 1}</span><span>(Seat Capacity: {screenDetail.capacity})</span></p>
                                    <a href={`${Req_Screen_URL}/${screenDetail.layout_image}`} target="_blank" download={`${theaterDetails.name}_screen_${index + 1}.png`} className="download_btn"><i className="fa-solid fa-cloud-arrow-down"></i>Download</a>
                                    <a className="upload_btn" onClick={() => handleSettingModal(`${theaterDetails.name}`, `Screen ${index + 1}`, `${screenDetail.screen_id}`, `${screenDetail.capacity}`)}><i className="fa-solid fa-cloud-arrow-up"></i>Upload</a>
                                    <span className="view_btn" onClick={() => handleViewModal(`${theaterDetails.name}`, `Screen ${index + 1}`, `${screenDetail.screen_id}`, `${screenDetail.capacity}`)}><i className="fa-solid fa-eye"></i></span>
                                </div>
                                )
                            }
                        </div>
                        <div className="contact_info_sec">
                            <div className="contact_info">
                                <p><span><i className="fa-solid fa-phone"></i></span>{userDetails.phone}</p>
                                <p><span><i className="fa-solid fa-envelope"></i></span>{userDetails.email}</p>
                            </div>
                            <div className="status_sec">
                                <span>Change Status :</span>
                                <div className="select_box">
                                    <div className="dropdown_btn" onClick={handleDropdown}>
                                        <p>{status}</p>
                                        <i class={`fa-solid fa-angle-down ${statusDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${statusDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                statuses.map((status, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setStatus(status)
                                                            setStatusDropdown(false)
                                                        }}
                                                    >{status}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {status === 'Reject' && (
                            <div className="reject_reason">
                                <div className="input_box">
                                    <input type="text" name="reason" value={reason} onChange={handleReason} required />
                                    <span>Reject Reason</span>
                                </div>
                            </div>
                        )}
                        {status === 'Complete' && (
                            <div className="complete_message_sec">
                                <p>NOTE: Please settle Admin & Theater commissions after this successful registration by the <span>"Edit"</span> option of this Theater.</p>
                            </div>
                        )}
                        <div className="modal_button_sec">
                            <a onClick={handleCloseModal}><span><i className="fa-solid fa-xmark"></i>Close</span></a>
                            <button type="submit"><span><i className="fa-solid fa-pen-to-square"></i>Update</span></button>
                        </div>
                    </form>
                </div>
            </ReqTheaterModalWrapper>

            <SeatSetting settingModalShow={settingModalShow} setSettingModalShow={setSettingModalShow} settingModalData={settingModalData} />
            <ScreenView viewModalShow={viewModalShow} setViewModalShow={setViewModalShow} viewModalData={viewModalData} setViewModalData={setViewModalData} />
        </>
    );
}



export default ModalReqTheater;