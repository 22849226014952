import { useEffect, useState } from "react";
import { ModalTheaterDetailsBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Theater_Details_URL, Settlement_Invoice_Download_URL, Settlement_Record_Update_URL } from "../API/Api";


function ModalTheaterDetails({theaterDetailsModalShow, setTheaterDetailsModalShow, theaterName, setTheaterName}) {
    
    const [screenNo, setScreenNo] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [address, setAddress] = useState('');
    const [onlineBookings, setOnlineBookings] = useState('');
    const [offlineBookings, setOfflineBookings] = useState('');
    const [adminCommission, setAdminCommission] = useState({});
    const [commission, setCommission] = useState('');
    const [commissions, setCommissions] = useState([]);
    const [multiCommissions, setMultiCommissions] = useState(false);
    const [settlementAmounts, setSettlementAmounts] = useState([]);
    const [count, setCount] = useState(false);
    const [shows, setShows] = useState([]);
    const [ticketPriceCheckItems, setTicketPriceCheckItems] = useState([]);
    const [theaterCommissionCheckItems, setTheaterCommissionCheckItems] = useState([]);

    useEffect(() => {
        axios.get(`${Fetch_Theater_Details_URL}?name=${theaterName}`)
        .then(res => {
            console.log(res);
            setScreenNo(res.data.screen_no);
            setGstNo(res.data.gst_no);
            setAddress(res.data.address);
            setOnlineBookings(res.data.online_bookings);
            setOfflineBookings(res.data.offline_bookings);
            setAdminCommission(res.data.admin_commissions[0]);
            setSettlementAmounts(res.data.comission_settlement);
            if(res.data.commission_type == "Single Commission") {
                setCommission(res.data.commission);
                setMultiCommissions([]);
                setMultiCommissions(false);
            } else if(res.data.commission_type == "Multiple Commissions") {
                setMultiCommissions(true);
                setCommission('');
                console.log(JSON.parse(res.data.commission));
                setCommissions(JSON.parse(res.data.commission));
            }
            const moviesWithFormattedDate = res.data.shows.map(show => ({
                ...show,
                formatted_date: show.date.split(',')[0] 
            }));

            if(res.data.count > 0){
                setCount(true);
                setShows(moviesWithFormattedDate);
            } else {
                setCount(false);
                setShows([]);
            }
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, [theaterName]);

    const closeModal = () => {
        setTheaterName('');
        setTheaterDetailsModalShow(false);
    };

    const handleTicketPriceCheckboxChange = (index, date) => {
        setTicketPriceCheckItems(prevState => {
            if (!prevState.includes(index)) {
                return [...prevState, index];
            } 
            return prevState;
        });
        
        const inputs = {
            theaterName: theaterName,
            settlementType: "Ticket Price",
            date: date
        };

        axios.post(Settlement_Record_Update_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        })
    };

    const handleTheaterCommissionCheckboxChange = (index, date) => {
        setTheaterCommissionCheckItems(prevState => {
            if (!prevState.includes(index)) {
                return [...prevState, index];
            } 
            return prevState;
        });

        const inputs = {
            theaterName: theaterName,
            settlementType: "Theater Commission",
            date: date
        };

        axios.post(Settlement_Record_Update_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        })
    };

    const handleTicketPriceInvoiceDownload = (date, amount) => {
        const inputs = {
            theaterName: theaterName,
            gstNo: gstNo,
            address: address,
            item: "Ticket Price",
            date: date,
            amount: amount
        };
        
        axios.post(Settlement_Invoice_Download_URL, inputs, {
            responseType: 'blob', 
        })
        .then(res => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
    
            // Create a link element to initiate download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Ticket-Invoice-${new Date().toISOString()}.pdf`);
            document.body.appendChild(link);
            link.click();
    
            // Clean up the link element
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(err => {
            console.error('Error downloading report:', err);
        })
    }

    const handleCommissionSettleInvoiceDownload = (date, amount) => {
        const inputs = {
            theaterName: theaterName,
            gstNo: gstNo,
            address: address,
            item: "Theater Commission",
            date: date,
            amount: amount
        };
        
        axios.post(Settlement_Invoice_Download_URL, inputs, {
            responseType: 'blob', 
        })
        .then(res => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
    
            // Create a link element to initiate download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Ticket-Invoice-${new Date().toISOString()}.pdf`);
            document.body.appendChild(link);
            link.click();
    
            // Clean up the link element
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(err => {
            console.error('Error downloading report:', err);
        })
    }

    return(
        <>
            <ModalTheaterDetailsBox className={theaterDetailsModalShow ? 'active' : ''}>
                <div className={`details_inner ${theaterDetailsModalShow ? 'active' : ''}`}>
                    <div className="top_part">
                        <div className="top_inner">
                            <h3>{theaterName}</h3>
                            <a onClick={closeModal}><i class="fa-solid fa-arrow-left-long"></i>Back</a>
                        </div>
                    </div>
                    <div className="body_part">
                        <div className="info_box_sec">
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Total No. Screens</h4>
                                        <p>{screenNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-clapperboard"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>GST No.</h4>
                                        <p>{gstNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-file-shield"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Online Bookings</h4>
                                        <p>{onlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-house-signal"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Offline Bookings</h4>
                                        <p>{offlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                </div>
                            </div>
                            {
                                !multiCommissions && 
                                <div className="info_box">
                                    <div className="box_inner">
                                        <div className="inner_item">
                                            <h4>Commission (Rs.)</h4>
                                            <p>{parseFloat(commission).toFixed(2)}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fa-solid fa-hand-holding-dollar"></i>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            multiCommissions &&
                            <div className="theater_commission_sec">
                                <h3>Theater Commissions</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Price Range (Rs.)</th>
                                            {
                                                commissions.map((commission, i) => 
                                                    <th key={i}>{commission.range_slab}</th>
                                                )
                                            }
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Commission (Rs.)</td>
                                                {
                                                    commissions.map((commission, i) => 
                                                        <td key={i}>{parseFloat(commission.amount).toFixed(2)}</td>
                                                    )
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            adminCommission && 
                            <div className="admin_commission_sec">
                                <h3>Admin Commissions</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Price Range (Rs.)</th>
                                            {
                                                Object.keys(adminCommission).map((range, index) => (
                                                    <th key={index}>{range}</th>
                                                ))
                                            }
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Commission (Rs.)</td>
                                                {
                                                    Object.values(adminCommission).map((value, index) => (
                                                        <td key={index}>{value !== null ? parseFloat(value).toFixed(2) : 'NA'}</td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className="settlement_sec">
                            <div className="settlement_price_sec">
                                <h3>Ticket Price Settlement</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Date</th>
                                            <th>Amount (Rs.)</th>
                                            <th>Payment</th>
                                            <th>Invoice</th>
                                        </thead>
                                        <tbody>
                                            {
                                                settlementAmounts.length > 0 ?
                                                settlementAmounts.map((amount, i) => 
                                                    <tr key={i}>
                                                        <td>{i + 1}.</td>
                                                        <td>{amount.date}</td>
                                                        <td>{parseFloat(amount.total_ticket_price).toFixed(2)}</td>
                                                        <td>
                                                            <div className="check_box">
                                                                <input type="checkbox" name="payment-check" id={`check-${i + 1}`} checked={ticketPriceCheckItems.includes(i) || amount.ticket_price_settlement_status === "done"} onChange={() => handleTicketPriceCheckboxChange(i, amount.date)} />
                                                                <label htmlFor={`check-${i + 1}`}>
                                                                    <i className="fa-solid fa-check"></i>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><a className={ticketPriceCheckItems.includes(i) || amount.ticket_price_settlement_status === "done" ? '' : 'disable'} onClick={() => handleTicketPriceInvoiceDownload(amount.date, amount.total_ticket_price)}><i className="fa-solid fa-download"></i></a></td>
                                                    </tr> 
                                                )
                                                :
                                                <tr>
                                                    <p>No data available</p>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="settlement_price_sec">
                                <h3>Theater Commission Settlement</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Date</th>
                                            <th>Amount (Rs.)</th>
                                            <th>Payment</th>
                                            <th>Invoice</th>
                                        </thead>
                                        <tbody>
                                            {
                                                settlementAmounts.length > 0 ?
                                                settlementAmounts.map((amount, i) => 
                                                    <tr key={i}>
                                                        <td>{i + 1}.</td>
                                                        <td>{amount.date}</td>
                                                        <td>{parseFloat(amount.total_theater_commission).toFixed(2)}</td>
                                                        <td>
                                                            <div className="check_box">
                                                                <input type="checkbox" name="payment-check" id={`box-${i + 1}`} checked={theaterCommissionCheckItems.includes(i) || amount.commission_settlement_status === "done"} onChange={() => handleTheaterCommissionCheckboxChange(i, amount.date)} />
                                                                <label htmlFor={`box-${i + 1}`}>
                                                                    <i className="fa-solid fa-check"></i>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><a className={theaterCommissionCheckItems.includes(i) || amount.commission_settlement_status === "done" ? '' : 'disable'} onClick={() => handleCommissionSettleInvoiceDownload(amount.date, amount.total_theater_commission)}><i className="fa-solid fa-download"></i></a></td>
                                                    </tr> 
                                                )
                                                :
                                                <tr>
                                                    <p>No data available</p>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="movie_list_sec">
                            <h3>Movie List</h3>
                            <div className="table_sec">
                                <div className="sec_inner">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Movie Name</th>
                                            <th>Language</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Ticked Booked</th>
                                        </thead>
                                        <tbody>
                                            {
                                                count ?
                                                (shows && shows.map((show, index) => 
                                                    <tr key={index}>
                                                        <td>{index + 1}.</td>
                                                        <td>{show.movie_name}</td>
                                                        <td>{show.language}</td>
                                                        <td>{show.formatted_date}</td>
                                                        <td>{show.time}</td>
                                                        <td>{show.total_booking}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <p>No Movies Found</p>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalTheaterDetailsBox>
        </>
    );
}


export default ModalTheaterDetails;