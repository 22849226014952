import { styled } from "@mui/material";


export const SidebarWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FC6736;

    .sidebar_logo {
        position: relative;
        width: 100%;
        height: 80px;
        padding: 18px 0px;
        border-bottom: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            position: relative;
            height: 100%;
            width: auto;
        }

        h3 {
            position: relative;
            margin-left: 10px;
            font-family: arista-pro-alt-semibold;
            font-size: 22px;
            letter-spacing: 0.5px;
            color: #fff;

            span {
                font-family: arista-pro-trial-semibold;
            }
        }
    }

    .sidebar_items {
        position: relative;
        width: 100%;
        height: calc(100% - 80px);
        padding: 40px 0;
        padding-left: 10px;

        a {
            position: relative;
            width: 100%;
            text-decoration: none;
            padding: 4px 15px;
            padding-right: 25px;
            display: flex;
            cursor: pointer;
            border-radius: 10px 0 0 10px;
            transition: all 0.6s;

            &.active {
                padding: 15px;
                background: #fff;
                margin: 12px 0;
                transition: all 0.6s;

                span:nth-child(1) {
                    position: absolute;
                    top: -15px;
                    right: 0;
                    width: 15px;
                    height: 15px;
                    background: #FFF;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #FC6736;
                        border-bottom-right-radius: 15px;
                    }
                }

                span:nth-child(2) {
                    position: absolute;
                    bottom: -15px;
                    right: 0;
                    width: 15px;
                    height: 15px;
                    background: #FFF;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #FC6736;
                        border-top-right-radius: 15px;
                    }
                }
            }

            p {
                position: relative;
                width: 100%;
                padding: 15px 20px;
                display: flex;
                align-items: center;
                text-decoration: none;
                border-radius: 6px;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                color: rgba(255,255,255,0.8);
                letter-spacing: 0.4px;
                transition: all 0.5s;

                &:hover {
                    background: rgba(255,255,255,0.2);
                    color: #fff;
                    transition: all 0.5s;
                }

                i {
                    position: relative;
                    width: 35px;
                    font-size: 20px;
                }
            }

            &.active p {
                background: #FC6736;
                color: #FFF;
                transition: all 0.5s;
            }
        }

        .dropdown_item {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .dropdown_btn {
                position: relative;
                width: 100%;
                padding: 4px 15px;
                padding-right: 25px;

                li {
                    position: relative;
                    list-style: none;
                    width: 100%;
                    padding: 15px 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 6px;
                    color: rgba(255,255,255,0.8);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    letter-spacing: 0.4px;
                    cursor: pointer;
                    transition: all 0.5s;

                    i:nth-child(1) {
                        position: relative;
                        width: 35px;
                        font-size: 20px;
                    }

                    i:last-child {
                        position: relative;
                        margin-left: auto;
                        font-size: 16px;
                        transition: all 0.6s;

                        &.rotate {
                            transform: rotate(90deg);
                            transition: all 0.6s;
                        }
                    }

                    &:hover {
                        background: rgba(255,255,255,0.2);
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
            }

            .dropdown {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                max-height: 0;
                overflow: hidden;
                transition: all 0.6s;

                &.active {
                    max-height: 400px;
                    transition: all 0.6s;
                }
            }
        }
    }
`;

export const Nav = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px 60px;

    .nav_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .side_bar_toggler {
            position: relative;
            cursor: pointer;
            color: #555;
            transition: 0.5s;

            &:hover {
                color: #FC6736;
                transition: 0.5s;
            }

            i {
                font-size: 21px;
            }
        }

        .page_name {
            position: relative;
            margin-left: 30px;

            h3 {
                position: relative;
                font-size: 27px;
                font-family: 'Oleo Script', cursive;
                color: #333;
            }
        }

        .nav_items {
            position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;

            .notification {
                position: relative;

                .notification_btn {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid rgb(225, 224, 234);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    i {
                        position: relative;
                        font-size: 21px;
                        color: #777;
                    }
                }

                .notification_dropdown {
                    position: absolute;
                    top: 60px;
                    left: calc(50% - 125px);
                    width: 250px;
                    background: #FFF;
                    z-index: 50;
                    border-radius: 8px;
                    box-shadow: 5px 7px 18px rgba(0,0,0,0.3);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out; 

                    &.active {
                        max-height: 400px;
                        transition: all 0.5s ease-in-out;
                    }
                    
                    .dropdown_inner {
                        position: relative;
                        width: 100%;
                        padding: 15px 20px;
                        padding-right: 5px;

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            max-height: 300px;
                            padding-right: 15px;
                            overflow-y: auto;
                            

                            &::-webkit-scrollbar {
                                width: 4px;
                            }

                            &::-webkit-scrollbar-track {
                                background: transparent;
                                border-radius: 10px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #FC6736;
                                border-radius: 10px;
                            }
                        }

                        li {
                            position: relative;
                            width: 100%;
                            list-style: none;
                            border-bottom: 1px solid #E1E0EA;

                            &:last-of-type {
                                border-bottom: none;
                            }

                            p {
                                position: relative;
                                width: 100%;
                                padding: 10px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                border-radius: 5px;
                                color: #777;
                            }

                            &.unread p {
                                color: #111;
                                background: rgba(225, 224, 234, 0.5);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .profile {
                position: relative;
                margin-left: 25px;

                .profile_btn {
                    position: relative;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .user_img {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    p {
                        position: relative;
                        margin-left: 10px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 17px;
                        font-weight: 500;
                        color: #333;
                        line-height: 1;
                        max-width: 150px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    i {
                        position: relative;
                        margin-left: 8px;
                        font-size: 16px;
                        transition: all 0.5s;

                        &.rotate {
                            transform: rotate(-180deg);
                            transition: all 0.5s;
                        }
                    }
                }

                .profile_dropdown {
                    position: absolute;
                    top: 60px;
                    left: 0;
                    width: 100%;
                    background: #FFF;
                    z-index: 50;
                    border-radius: 8px;
                    box-shadow: 5px 7px 18px rgba(0,0,0,0.3);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.5s ease-in-out;

                    &.active {
                        max-height: 200px;
                        transition: all 0.5s ease-in-out;
                    }

                    li {
                        position: relative;
                        width: 100%;
                        list-style: none;
                        padding: 15px 30px;
                        font-family: 'Poppins', sans-serif;
                        line-height: 1;
                        font-size: 17px;
                        font-weight: 500;
                        color: #555;
                        transition: 0.5s;
                        cursor: pointer;

                        &:hover {
                            color: #FC6736;
                            transition: 0.5s;
                        }

                        i {
                            position: relative;
                            width: 32px;
                            font-size: 19px;
                        }
                    }
                }
            }
        }
    }
`;