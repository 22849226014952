import { styled } from "@mui/material";


export const ImageUploadWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    pointer-events: none;
    transition: all 0.7s;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        pointer-events: initial;
        transition: all 0.7s;
    }

    .upload_box {
        position: relative;
        width: 375px;
        min-height: 300px;
        background: #fff;
        border-radius: 10px;
        box-shadow:  10px 15px 25px rgba(0,0,0,0.4),
                     -5px -5px 15px rgba(50,50,50,0.2);
        padding: 35px 30px;
        display: flex;
        flex-direction: column;

        span {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            font-size: 14px;
            color: #f00;
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .select_box {
                position: relative;
                width: 100%;

                input {
                    display: none;
                }

                .dropdown_btn {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    border: 2px solid #E1E0EA;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 25px;
                    font-family: 'Poppins', sans-serif;
                    cursor: pointer;

                    p {
                        position: relative;
                        max-width: calc(100% - 15px);
                        padding-right: 15px;
                        font-size: 16px;
                        line-height: 1.5;
                        color: #555;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    i {
                        font-size: 15px;
                        transition: 0.7s;

                        &.rotate {
                            transform: rotate(-180deg);
                            transition: 0.5s;
                        }
                    }
                }

                .dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 15;
                    width: 100%;
                    background: #fff;
                    border-radius: 6px;
                    box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.7s;

                    &.active {
                        max-height: 350px;
                        transition: all 0.7s;
                    }

                    .dropdown_inner {
                        position: relative;
                        width: 100%;
                        padding: 15px 0;

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 0px 15px;
                            max-height: 250px;
                            overflow: auto;

                            &::-webkit-scrollbar {
                                width: 4px;
                            }

                            &::-webkit-scrollbar-track {
                                background: transparent;
                                border-radius: 10px;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #FC6736;
                                border-radius: 10px;
                            }

                            li {
                                position: relative;
                                list-style: none;
                                margin: 2px 0;
                                padding: 13px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 15px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #02C0FF;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }

            input[type="file"] {
                display: none;
            }

            .preview_box {
                position: relative;
                margin-top: 25px;
                width: 200px;
                height: 200px;
                border: 1px dashed #AAA;
                border-radius: 6px;
                cursor: pointer;

                .upload_text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: #cfcfcf;

                    i {
                        font-size: 40px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 1.3;
                        margin-top: 15px;
                    }
                }

                .img_box {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                        object-fit: cover;
                    }
                }
            }

            .btn_sec {
                position: relative;
                margin-top: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                    position: relative;
                    width: 135px;
                    height: 50px;
                    border: none;
                    outline: none;
                    border-radius: 6px;
                    cursor: pointer;
                    font-size: 17px;
                    letter-spacing: 0.55px;
                    font-weight: 500;
                    transition: all 0.5s;
                    color: #fff;

                    &:first-of-type {
                        background: #FC6736;
                    }

                    &:last-of-type {
                        background: #3FE44F;
                    }

                    &:hover {
                        box-shadow: 5px 7px 15px rgba(0,0,0,0.4);
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
`;

export const ReqTheaterModalWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .view_box {
        position: relative;
        width: 800px;
        min-height: 250px;
        background: #FFF;
        border-radius: 10px;
        box-shadow:  10px 15px 25px rgba(0,0,0,0.4),
                     -5px -5px 15px rgba(50,50,50,0.2);
        padding: 40px 30px;
        transform: translateX(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateX(0px);
            transition: all 0.8s;
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .theater_info_box {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .info_box {
                    position: relative;
                    width: max-content;
                    display: flex;
                    align-items: center;
                    padding: 0px 15px;
                    margin-bottom: 10px;

                    &.fullWidth {
                        width: 100%;
                    }

                    h5 {
                        position: relative;
                        font-size: 17px;
                        font-weight: 500;
                        color: #000;
                        width: max-content;
                    }

                    p {
                        position: relative;
                        font-size: 17px;
                        color: #444;
                        margin-left: 10px;
                    }
                }
            }

            .screen_info_box {
                position: relative;
                width: 100%;
                padding: 0px 15px;
                margin-top: 15px;
                display: flex;
                flex-direction: column;

                h5 {
                    position: relative;
                    width: max-content;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 25px;

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        width: 65%;
                        height: 3px;
                        background: #FC6736;
                        border-radius: 25px;
                    }
                }

                .screen_info {
                    position: relative;
                    width: 100%;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;

                    p {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        margin-right: auto;

                        span {

                            &:first-of-type {
                                font-size: 17px;
                                color: #000;
                            }

                            &:last-of-type {
                                margin-top: 5px;
                                font-size: 15px;
                                color: #555;
                            }
                        }
                    }

                    a {
                        position: relative;
                        margin-right: 20px;
                        width: 175px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        line-height: 1;
                        cursor: pointer;
                        border-radius: 6px;
                        font-weight: 500;
                        color: #FFF;
                        text-decoration: none;

                        i {
                            position: relative;
                            font-size: 18px;
                            margin-right: 8px;
                        }

                        &.download_btn {
                            background: #FC6736;
                        }

                        &.upload_btn {
                            background: #3FE44F;
                        }
                    }

                    span.view_btn {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #02C0FF;
                        border-radius: 6px;
                        color: #FFF;
                        font-size: 18px;
                        cursor: pointer;
                    }
                }
            }

            .contact_info_sec {
                position: relative;
                padding: 0px 15px;
                margin-top: 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .contact_info {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    p {
                        position: relative;
                        margin-bottom: 8px;
                        font-size: 15px;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        color: #444;
                        letter-spacing: 0.4px;

                        span {
                            position: relative;
                            width: 23px;
                            color: #F00;
                        }
                    }
                }

                .status_sec {
                    position: relative;
                    display: flex;
                    align-items: center;

                    span {
                        position: relative;
                        font-size: 15px;
                        color: #333;
                    }

                    .select_box {
                        position: relative;
                        margin-left: 20px;

                        input {
                            display: none;
                        }

                        .dropdown_btn {
                            position: relative;
                            width: 200px;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 25px;
                            font-family: 'Poppins', sans-serif;
                            cursor: pointer;

                            i{
                                position: relative;
                                color: #444;
                                transition: all 0.5s;

                                &.active {
                                    transform: rotate(-180deg);
                                    transition: all 0.5s;
                                }
                            }
                        }

                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            background: #FFF;
                            z-index: 50;
                            border-radius: 5px;
                            box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.6s;

                            &.active {
                                max-height: 130px;
                                transition: all 0.6s;
                            }

                            ul {
                                position: relative;
                                width: 100%;
                                padding: 10px 10px;
                                max-height: 128px;
                                overflow: auto;

                                li {
                                    position: relative;
                                    list-style: none;
                                    padding: 11px 15px;
                                    color: #555;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    line-height: 1;
                                    border-radius: 4px;
                                    transition: all 0.5s;
                                    cursor: pointer;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: all 0.5s;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .reject_reason {
                position: relative;
                width: 100%;
                margin-top: 25px;
                padding: 0px 15px;

                .input_box {
                    position: relative;
                    width: 100%;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        outline: none;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }
            }

            .complete_message_sec {
                position: relative;
                width: 100%;
                margin-top: 20px;
                padding: 0px 15px;

                p {
                    position: relative;
                    font-size: 16px;
                    line-height: 1.5;
                    font-weight: 500;
                    color: #F00;

                    span {
                        color: #02C0FF;
                    }
                }
            }

            .modal_button_sec {
                position: relative;
                width: 100%;
                margin-top: 30px;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    position: relative;
                    width: 180px;
                    height: 55px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFF;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    border: 2px solid #3FE44F;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;

                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                        background: #3FE44F;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #3FE44F;
                        transition: all 0.4s;
                    }
                }

                a {
                    position: relative;
                    margin-right: 20px;
                    width: 180px;
                    height: 55px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFF;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #000;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;

                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #000;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #000;
                        transition: all 0.4s;
                    }
                }
            }
        }
    }
`;

export const ModalSettingWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .wrapper_inner {
        position: relative;
        width: 700px;
        min-height: 150px;
        background: #FFF;
        border-radius: 10px;
        box-shadow:  10px 15px 25px rgba(0,0,0,0.4),
                     -5px -5px 15px rgba(50,50,50,0.2);
        padding: 40px 45px;
        transform: scale(0);
        transition: all 0.8s;

        &.active {
            transform: scale(1);
            transition: all 0.8s;
        }

        .setting_box {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .screen_details {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;

                h5 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 18px;
                    font-weight: 600;
                    color: #222;
                }

                p {
                    position: relative;
                    font-size: 17px;
                    font-weight: 500;
                    color: #555;
                    margin-left: 10px;
                }
            }

            .section_input_box {
                position: relative;
                width: 100%;
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .input_box {
                    position: relative;
                    width: 380px;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-size: 16px;
                        outline: none;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                a {
                    position: relative;
                    width: 180px;
                    height: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #02C0FF;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFF;
                    cursor: pointer;
                    border-radius: 6px;
                }
            }

            .section_box {
                position: relative;
                margin-top: 20px;
                width: 100%;
                display: flex;
                align-items: center;

                h5 {
                    position: relative;
                    font-size: 17px;
                    font-weight: 500;
                    color: #222;
                }

                p {
                    position: relative;
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #000;
                }
            }

            .section_setting_box {
                position: relative;
                width: 100%;
                margin-top: 25px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                .event {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .select_box {
                    position: relative;
                    width: 32%;
                    margin-bottom: 20px;

                    &.select_align {
                        width: 30%;
                        margin-left: 20px;
                        margin-right: auto;
                        
                        &.setted {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        transition: 0.5s;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            pointer-events: none;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.rotate {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 15;
                        width: 100%;
                        background: #fff;
                        border-radius: 6px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.7s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.7s;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px;

                            li {
                                position: relative;
                                width: 100%;
                                list-style: none;
                                padding: 12px 15px;
                                font-size: 14px;
                                line-height: 1;
                                transition: 0.4s;
                                color: #222;
                                border-radius: 4px;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: 0.4s;
                                }
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .input_box {
                    position: relative;
                    width: 30%;
                    margin-bottom: 20px;

                    &.sec_name {
                        width: 32%;
                    }

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        outline: none;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .button_box {
                    position: relative;
                    margin-left: auto;
                    width: 32%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 20px;

                    a {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #02C0FF;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        border-radius: 6px;
                    }
                }

                .settled_box {
                    position: relative;
                    margin-top: 10px;
                    margin-bottom: 20px;

                    p {
                        position: relative;
                        font-size: 16px;
                        font-weight: 500;
                        color: #3FE44F;
                    }
                }
            }

            .row_setting_box {
                position: relative;
                margin-top: 15px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                h5 {
                    position: relative;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    color: #444;
                    margin-bottom: 25px;

                    p {
                        position: relative;
                        width: max-content;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -5px;
                            width: 60%;
                            height: 3px;
                            background: #FFC76C;
                            border-radius: 25px;
                        }
                    }
                }

                .select_box {
                    position: relative;
                    width: 32%;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        transition: 0.5s;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            pointer-events: none;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.rotate {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    .dropdown {
                        position: absolute;
                        top: 55px;
                        left: 0;
                        z-index: 15;
                        width: 100%;
                        background: #fff;
                        border-radius: 6px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.7s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.7s;
                        }

                        .dropdown_inner {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            ul {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                max-height: 80px;
                                overflow: auto;
                                scrollbar-width: none;

                                li {
                                    position: relative;
                                    width: 100%;
                                    list-style: none;
                                    padding: 12px 15px;
                                    font-size: 14px;
                                    line-height: 1;
                                    transition: 0.4s;
                                    color: #222;
                                    border-radius: 4px;
                                    cursor: pointer;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: 0.4s;
                                    }
                                }
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .input_box {
                    position: relative;
                    width: 32%;
                    margin-bottom: 13px;

                    &.sec_name {
                        width: 40%;
                    }

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        outline: none;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .button_box {
                    position: relative;
                    width: 32%;

                    a {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        background: #02C0FF;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        border-radius: 6px;

                        i {
                            font-size: 17px;
                            margin-right: 10px;
                        }
                    }
                }

                .show_result_sec {
                    position: relative;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    width: 100%;
                    min-height: 55px;
                    border: 2px solid #FC6736;
                    border-radius: 6px;
                    padding: 10px 15px;

                    .result_inner {
                        position: relative;
                        width: 100%;
                        max-height: 120px;
                        overflow-y: auto;
                        display: flex;
                        flex-wrap: wrap;

                        p {
                            position: relative;
                            padding: 10px 15px;
                            font-size: 16px;
                            color: #444;

                            b {
                                font-weight: 500;
                                color: #000;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .buttons_sec {
                position: relative;
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                a {
                    position: relative;
                    width: 150px;
                    height: 50px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;

                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &.close {
                        border: 2px solid #000;

                        &::before {
                            background: #000;
                        }

                        &:hover span {
                            color: #000;
                            transition: all 0.4s;
                        }
                    }

                    &.submit {
                        border: 2px solid #3FE44F;
                        margin-left: 25px;

                        &::before {
                            background: #3FE44F;
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.4s;
                        }
                    }
                }
            }
        }
    }
`;

export const ScreenViewWrapper = styled('div')`
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    height: 100vh;
    z-index: 1200;
    opacity: 0;
    pointer-events: none;
    transition: all 0.9s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .view_inner {
        position: relative;
        width: 100%;
        height: 100%;
        background: #FFF;
        display: flex;
        flex-direction: column;
        transform: translateX(100%);
        transition: all 1s ease-in-out;

        &.active {
            transform: translateX(0);
            transition: all 1s ease-in-out;
        }

        .top_part {
            position: relative;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #E1E0EA;
            padding: 5px 60px;

            .top_inner {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left_items {
                    position: relative;
                    display: flex;
                    align-items: center;

                    h3 {
                        position: relative;
                        font-size: 30px;
                        font-family: 'Oleo Script',cursive;
                        color: #333;
                    }

                    p {
                        position: relative;
                        margin-left: 15px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #333;
                    }
                }

                .right_items {
                    position: relative;
                    display: flex;
                    align-items: center;

                    p {
                        position: relative;
                        font-size: 18px;
                        color: #000;
                        font-weight: 500;
                    }

                    span {
                        font-size: 18px;
                        color: #333;
                        margin-left: 10px;
                    }
                }
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            padding: 50px 60px;

            .body_inner {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .screen_sec {
                    position: relative;
                    width: 100%;
                    height: calc(100% - 60px);
                    padding-bottom: 60px;
                    overflow: hidden;

                    .sec_inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        overflow-y: auto;
                        scrollbar-width: none;

                        .swiper {
                            position: relative;
                            width: 100%;

                            .swiper-slide {
                                position: relative;
                                width: max-content !important;
                                min-width: 100%;
                                min-height: 250px;

                                &:last-of-type {
                                    width: 0 !important;
                                    min-width: 0 !important;
                                }

                                .screen_box {
                                    position: relative;
                                    min-width: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    .display_image {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        justify-content: center;
                                        margin-bottom: 70px;

                                        img {
                                            position: relative;
                                            width: 600px;
                                            display: flex;
                                        }
                                    }

                                    .seat_sections {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        .section {
                                            position: relative;
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            margin-bottom: 40px;

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }

                                            .sec_name {
                                                position: relative;
                                                width: 100%;
                                                padding-bottom: 15px;
                                                border-bottom: 1px solid #E1E0EA;

                                                h5 {
                                                    position: relative;
                                                    font-family: 'Lemonada', cursive;
                                                    font-size: 16px;
                                                    line-height: 1;
                                                    font-weight: 500;
                                                    color: #02C0FF;
                                                }
                                            }

                                            .sec_seat_rows {
                                                position: relative;
                                                width: 100%;
                                                margin-top: 30px;
                                                display: flex;
                                                flex-direction: column-reverse;

                                                .seat_row {
                                                    position: relative;
                                                    width: 100%;
                                                    padding: 0px 45px;
                                                    margin-top: 18px;

                                                    &:last-of-type {
                                                        margin-top: 0;
                                                    }

                                                    .seats {
                                                        position: relative;
                                                        width: 100%;
                                                        display: flex;
                                                        flex-direction: row-reverse;
                                                        align-items: center;

                                                        &.align_right {
                                                            justify-content: flex-start;
                                                        }

                                                        &.align_middle {
                                                            justify-content: center;
                                                        }

                                                        li {
                                                            position: relative;
                                                            list-style: none;
                                                            width: 40px;
                                                            cursor: pointer;
                                                            display: flex;
                                                            flex-direction: column;
                                                            align-items: center;
                                                            color: #555;

                                                            i {
                                                                position: relative;
                                                                font-size: 15px;
                                                            }

                                                            p {
                                                                position: relative;
                                                                margin-top: 5px;
                                                                font-size: 12px;
                                                                line-height: 1;
                                                            }
                                                        }

                                                        &.gap_middle {
                                                            li.middle-seat {
                                                                margin-right: calc(100% / 31);
                                                            }
                                                        }

                                                        &.gap_left  {
                                                            li:nth-last-child(8) {
                                                                margin-right: calc(100% / 31);
                                                            }
                                                        }
                                                    }

                                                    .index {
                                                        position: absolute;
                                                        top: 30%;
                                                        right: 5px;
                                                        transform: translateY(-50%);
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                        color: #FC6736;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .button_sec {
                    position: relative;
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    a {
                        position: relative;
                        width: 150px;
                        height: 55px;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        overflow: hidden;
                        background: none;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #3FE44F;

                        span {
                            position: relative;
                            z-index: 5;
                            color: #FFF;
                            transition: all 0.5s;

                            i {
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #3FE44F;
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.5s;
                        }

                        &:hover:before {
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.5s;
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.4s;
                        }
                    }
                }
            }
        }
    }
`;

export const ModalTheaterDetailsBox = styled('div')`
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    height: 100vh;
    z-index: 1200;
    opacity: 0;
    pointer-events: none;
    transition: all 0.9s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .details_inner {
        position: relative;
        width: 100%;
        height: 100%;
        background: #FFF;
        display: flex;
        flex-direction: column;
        transform: translateX(100%);
        transition: all 1s ease-in-out;

        &.active {
            transform: translateX(0);
            transition: all 1s ease-in-out;
        }

        .top_part {
            position: relative;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #E1E0EA;
            padding: 5px 60px;

            .top_inner {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                h3 {
                    position: relative;
                    font-size: 27px;
                    font-family: 'Oleo Script',cursive;
                    color: #333;
                }

                a {
                    position: relative;
                    margin-left: auto;
                    font-size: 18px;
                    font-weight: 500;
                    color: #000;
                    cursor: pointer;

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }

        .body_part {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            padding: 50px 60px;
            overflow-y: auto;

            .info_box_sec {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .info_box {
                    position: relative;
                    width: 23.5%;
                    height: 100px;
                    border-radius: 10px;
                    padding: 25px 30px;
                    cursor: pointer;
                    transition: all 0.5s;
                    text-decoration: none;
                    margin-bottom: 30px;

                    &:nth-child(1) {
                        background: linear-gradient(to bottom, #fafafa 0%, rgba(28, 66, 255, 0.562) 100%);
                    }

                    &:nth-child(2) {
                        background: linear-gradient(to bottom, #fafafa 0%, rgba(63, 228, 79, 0.7) 100%);
                    }

                    &:nth-child(3) {
                        background: linear-gradient(to bottom, #fafafa 0%, rgba(2, 192, 255, 0.562) 100%);
                    }

                    &:nth-child(4) {
                        background: linear-gradient(to bottom, #fafafa 0%, #FFC76C 100%);
                    }

                    &:nth-child(5) {
                        background: linear-gradient(to bottom, #fafafa 0%, rgba(255, 51, 160, 0.689) 100%);
                    }

                    .box_inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: flex-end;

                        .inner_item {
                            position: relative;
                            width: calc(100% - 70px);
                            display: flex;
                            flex-direction: column;

                            h4 {
                                position: relative;
                                font-family: 'Lemonada', cursive;
                                font-size: 17px;
                                font-weight: 600;
                                color: #222;
                            }

                            p {
                                position: relative;
                                font-family: 'Poppins', sans-serif;
                                line-height: 1;
                                font-size: 16px;
                                margin-top: 8px;
                                font-weight: 500;
                                color: #444;
                            }
                        }

                        .icon {
                            position: relative;
                            width: 70px;
                            display: flex;
                            justify-content: flex-end;

                            i {
                                position: relative;
                                font-size: 40px;
                                color: #333;
                            }
                        }
                    }
                }
            }

            .theater_commission_sec {
                position: relative;
                width: 100%;
                margin-top: 35px;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                }

                .table_sec {
                    position: relative;
                    width: 100%;
                    margin-top: 20px;
                    padding-bottom: 35px;
                    overflow-x: auto;

                    table {
                        position: relative;
                        width: max-content;
                        display: flex;
                        flex-direction: column;

                        thead {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            display: flex;
                            background: #FC6736;
                            border-radius: 10px 10px 0 0 ;
                            border: 1px solid #FC6736;

                            th {
                                position: relative;
                                width: 150px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-family: 'Lemonada', cursive;
                                font-size: 14px;
                                color: #FFF;
                                padding: 0px 25px;
                                font-weight: 600;

                                &:first-of-type {
                                    width: 200px;
                                }
                            }
                        }

                        tbody {
                            position: relative;
                            width: 100%;
                            border: 1px solid #E1E0EA;
                            border-radius: 0 0 10px 10px;
                            display: flex;
                            flex-direction: column;
                            overflow-y: auto;
                            scrollbar-width: none;

                            tr {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                display: flex;

                                td {
                                    position: relative;
                                    width: 150px;
                                    height: 100%;
                                    padding: 0px 25px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: #444;

                                    &:first-of-type {
                                        width: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .admin_commission_sec {
                position: relative;
                width: 100%;
                margin-top: 35px;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                }

                .table_sec {
                    position: relative;
                    width: 100%;
                    margin-top: 20px;
                    padding-bottom: 35px;
                    overflow-x: auto;

                    table {
                        position: relative;
                        width: max-content;
                        display: flex;
                        flex-direction: column;

                        thead {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            display: flex;
                            background: #FC6736;
                            border-radius: 10px 10px 0 0 ;
                            border: 1px solid #FC6736;

                            th {
                                position: relative;
                                width: 150px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-family: 'Lemonada', cursive;
                                font-size: 14px;
                                color: #FFF;
                                padding: 0px 25px;
                                font-weight: 600;

                                &:first-of-type {
                                    width: 200px;
                                }
                            }
                        }

                        tbody {
                            position: relative;
                            width: 100%;
                            border: 1px solid #E1E0EA;
                            border-radius: 0 0 10px 10px;
                            display: flex;
                            flex-direction: column;
                            overflow-y: auto;
                            scrollbar-width: none;

                            tr {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                display: flex;

                                td {
                                    position: relative;
                                    width: 150px;
                                    height: 100%;
                                    padding: 0px 25px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: #444;

                                    &:first-of-type {
                                        width: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .settlement_sec {
                position: relative;
                margin-top: 50px;
                display: flex;
                justify-content: space-between;

                .settlement_price_sec {
                    position: relative;
                    width: 48%;

                    h3 {
                        position: relative;
                        font-family: 'Lemonada', cursive;
                        font-size: 18px;
                        font-weight: 600;
                        color: #000;
                    }

                    .table_sec {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 20px;

                        table {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            thead {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                display: flex;
                                background: #FC6736;
                                border-radius: 10px 10px 0 0 ;
                                border: 1px solid #FC6736;

                                th {
                                    position: relative;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 14px;
                                    color: #FFF;
                                    font-weight: 600;
                                    padding: 0px 25px;

                                    &:nth-child(1) {
                                        width: 15%;
                                    }

                                    &:nth-child(2) {
                                        width: 22%;
                                    }

                                    &:nth-child(3) {
                                        width: 25%;
                                    }

                                    &:nth-child(4) {
                                        width: 20%;
                                        justify-content: center;
                                    }

                                    &:nth-child(5) {
                                        width: 18%;
                                        justify-content: center;
                                    }
                                }
                            }

                            tbody {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                max-height: 300px;
                                overflow-y: auto;
                                border: 1px solid #E1E0EA;
                                border-radius: 0 0 10px 10px;

                                tr {
                                    position: relative;
                                    width: 100%;
                                    height: 55px;
                                    display: flex;

                                    &:nth-of-type(even) {
                                        background: rgba(2, 192, 255, 0.15);
                                    }

                                    td {
                                        position: relative;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        padding: 0px 25px;
                                        color: #444;
                                        font-size: 15px;

                                        &:nth-child(1) {
                                            width: 15%;
                                        }

                                        &:nth-child(2) {
                                            width: 22%;
                                        }

                                        &:nth-child(3) {
                                            width: 25%;
                                        }

                                        &:nth-child(4) {
                                            width: 20%;
                                            justify-content: center;

                                            .check_box {
                                                position: relative;

                                                input[type="checkbox"] {
                                                    display: none;
                                                }

                                                label {
                                                    position: relative;
                                                    width: 20px;
                                                    height: 20px;
                                                    border: 1px solid #B4B4B4;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    border-radius: 4px;
                                                    cursor: pointer;
                                                    transition: all 0.4s;
                                                    
                                                    i {
                                                        position: relative;
                                                        font-size: 11px;
                                                        color: #FFF;
                                                        opacity: 0;
                                                        transition: all 0.4s;
                                                    }
                                                }

                                                input[type="checkbox"]:checked ~ label {
                                                    border: 1px solid #3FE44F;
                                                    background: #3FE44F;
                                                    transition: all 0.4s;
                                                }

                                                input[type="checkbox"]:checked ~ label i {
                                                    opacity: 1;
                                                    transition: all 0.4s;
                                                }
                                            }
                                        }

                                        &:nth-child(5) {
                                            width: 18%;
                                            justify-content: center;

                                            a {
                                                position: relative;
                                                font-size: 18px;
                                                color: #02C0FF;
                                                cursor: pointer;

                                                &.disable {
                                                    opacity: 0.3;
                                                    pointer-events: none;
                                                }
                                            }
                                        }
                                    }

                                    p {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 15px;
                                        color: rgb(137, 137, 137);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .movie_list_sec {
                position: relative;
                width: 100%;
                margin-top: 50px;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 18px;
                    font-weight: 600;
                    color: #000;
                }

                  .table_sec {
                    position: relative;
                    width: 100%;
                    margin-top: 20px;

                    .sec_inner {
                        position: relative;
                        width: 100%;

                        table {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            thead {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                display: flex;
                                background: #FC6736;
                                border-radius: 10px 10px 0 0 ;
                                border: 1px solid #FC6736;

                                th {
                                    position: relative;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    font-family: 'Lemonada', cursive;
                                    font-size: 14px;
                                    color: #FFF;
                                    font-weight: 600;

                                    &:nth-child(1) {
                                        width: 10%;
                                        padding: 0px 25px;
                                    }

                                    &:nth-child(2) {
                                        width: 30%;
                                        padding: 0px 25px;
                                    }

                                    &:nth-child(3) {
                                        width: 15%;
                                        padding: 0px 25px;
                                        justify-content: center;
                                    }

                                    &:nth-child(4) {
                                        width: 15%;
                                        padding: 0px 25px;
                                        justify-content: center;
                                    }

                                    &:nth-child(5) {
                                        width: 15%;
                                        padding: 0px 25px;
                                        justify-content: center;
                                    }

                                    &:nth-child(6) {
                                        width: 15%;
                                        padding: 0px 25px;
                                        justify-content: center;
                                    }
                                }
                            }

                            tbody {
                                position: relative;
                                width: 100%;
                                max-height: 400px;
                                border: 1px solid #E1E0EA;
                                border-radius: 0 0 10px 10px;
                                display: flex;
                                flex-direction: column;
                                overflow-y: auto;
                                scrollbar-width: none;

                                tr {
                                    position: relative;
                                    width: 100%;
                                    min-height: 55px;
                                    display: flex;
                                    border-bottom: 1px solid #E1E0EA;

                                    &:last-of-type {
                                        border-bottom: none;
                                    }

                                    &:nth-of-type(even) {
                                        background: rgba(2, 192, 255, 0.15);
                                    }

                                    p {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    td {
                                        position: relative;
                                        padding: 15px 25px;
                                        display: flex;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 15px;
                                        line-height: 1.5;

                                        &:nth-child(1) {
                                            width: 10%;
                                        }

                                        &:nth-child(2) {
                                            width: 30%;
                                        }

                                        &:nth-child(3) {
                                            width: 15%;
                                            justify-content: center;
                                        }

                                        &:nth-child(4) {
                                            width: 15%;
                                            justify-content: center;
                                        }

                                        &:nth-child(5) {
                                            width: 15%;
                                            justify-content: center;
                                        }

                                        &:nth-child(6) {
                                            width: 15%;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ModalConfirmDeleteBox = styled('div')`
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .delete_box {
        position: relative;
        width: 400px;
        background: #FFF;
        border-radius: 10px;
        padding: 35px 40px;
        box-shadow:  10px 15px 25px rgba(0, 0, 0, 0.292),
                     -5px -5px 15px rgba(50,50,50,0.2);
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0);
            transition: all 0.8s;
        }

        .close_btn {
            position: absolute;
            top: 15px;
            right: 25px;

            a {
                position: relative;
                font-size: 18px;
                color: #878787;
                cursor: pointer;
            }
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            h4 {
                position: relative;
                width: 100%;
                height: 100px;
                font-family: 'Lemonada', cursive;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 17px;
                font-weight: 600;
                color: #E5322D;
            }

            .btn_sec {
                position: relative;
                margin-top: 10px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                button {
                    position: relative;
                    width: 120px;
                    height: 40px;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 15px;
                    font-weight: 500;
                    cursor: pointer;
                    border-radius: 6px;
                    transition: all 0.5s;

                    &:first-of-type {
                        border: 2px solid #000;
                        background: #000;
                        color: #FFF;

                        &:hover {
                            background: #FFF;
                            color: #000;
                        }
                    }

                    &:last-of-type {
                        border: 2px solid #02C0FF;
                        background: #02C0FF;
                        color: #FFF;

                        &:hover {
                            background: #FFF;
                            color: #02C0FF;
                        }
                    }
                }
            }
        }
    }
`;

export const ModalTheaterEditBox = styled('div')`
    position: fixed;
    top: 0;
    left: 300px;
    width: calc(100% - 300px);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &.active {
        opacity: 1;
        pointer-events: initial;
        transition: all 0.3s;
    }

    .edit_box {
        position: relative;
        width: 650px;
        min-height: 200px;
        background: #FFF;
        border-radius: 10px;
        padding: 35px 30px;
        box-shadow:  10px 15px 25px rgba(0, 0, 0, 0.292),
                     -5px -5px 15px rgba(50,50,50,0.2);
        transform: translateY(-100px);
        transition: all 0.8s;

        &.active {
            transform: translateY(0);
            transition: all 0.8s;
        }

        .close_btn {
            position: absolute;
            top: 15px;
            right: 25px;

            a {
                position: relative;
                font-size: 18px;
                color: #878787;
                cursor: pointer;
            }
        }

        .box_inner {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .heading {
                position: relative;
                width: 100%;
                padding: 0 10px;
                display: flex;
                align-items: flex-start;

                h3 {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 700;
                    color: #000;

                    span {
                        font-family: 'Oleo Script', cursive;
                        font-size: 25px;
                        color: #797979;
                        margin-right: 4px;
                    }
                }
            }

            .form_sec {
                position: relative;
                width: 100%;
                margin-top: 25px;
                display: flex;
                flex-direction: column;

                .commission_settlement_sec {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                        width: calc(100% - 20px);
                        height: 1px;
                        background: linear-gradient(to right, rgb(148, 148, 148), #FFF);
                    }

                    .sec_heading {
                        position: relative;
                        width: 100%;
                        padding: 0 10px;

                        h4 {
                            position: relative;
                            width: max-content;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 19px;
                            font-weight: 600;
                            color: #964B00;
                            padding-bottom: 2px;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 70%;
                                height: 2px;
                                border-radius: 20px;
                                background: #888;
                            }
                        }
                    }

                    .admin_commission_sec {
                        position: relative;
                        margin-top: 20px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        h5 {
                            position: relative;
                            padding: 0 10px;
                            font-size: 16px;
                            font-weight: 600;
                            color: #000;
                        }

                        .input_sec {
                            position: relative;
                            width: 100%;
                            margin-top: 15px;
                            display: flex;
                            justify-content: space-between;

                            .select_sec {
                                position: relative;
                                width: 40%;
                                padding: 0 10px;

                                .sec_inner {
                                    position: relative;
                                    width: 100%;

                                    input {
                                        display: none;
                                    }

                                    .select_btn {
                                        position: relative;
                                        width: 100%;
                                        height: 50px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 5px 20px;
                                        font-family: 'Poppins', sans-serif;
                                        cursor: pointer;

                                        p {
                                            position: relative;
                                            padding-right: 10px;
                                            width: calc(100% - 15px);
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            font-size: 15px;
                                            color: #444;
                                            transition: all 0.5s;
                                        }

                                        i{
                                            position: relative;
                                            font-size: 16px;
                                            color: #444;
                                            transition: all 0.5s;

                                            &.active {
                                                transform: rotate(-180deg);
                                                transition: all 0.5s;
                                            }
                                        }

                                        span {
                                            position: absolute;
                                            top: 50%;
                                            left: 20px;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 15px;
                                            line-height: 1;
                                            color: #555;
                                            pointer-events: none;
                                            transform: translateY(-50%);
                                            transition: 0.5s;
                                        }
                                    }

                                    input:focus ~ .select_btn,
                                    input:valid ~ .select_btn {
                                        border: 2px solid #FC6736;
                                    }

                                    input:focus ~ .select_btn span,
                                    input:valid ~ .select_btn span {
                                        top: 0%;
                                        left: 10px;
                                        font-size: 13px;
                                        padding: 0px 8px;
                                        background: #FFF;
                                        color: #02C0FF;
                                        font-weight: 500;
                                        transition: 0.5s;
                                    }

                                    .dropdown {
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        width: 100%;
                                        background: #FFF;
                                        z-index: 50;
                                        border-radius: 5px;
                                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                                        max-height: 0;
                                        overflow: hidden;
                                        transition: all 0.6s;

                                        &.active {
                                            max-height: 130px;
                                            transition: all 0.6s;
                                        }

                                        ul {
                                            position: relative;
                                            width: 100%;
                                            padding: 10px 10px;
                                            max-height: 128px;
                                            overflow: auto;

                                            li {
                                                position: relative;
                                                list-style: none;
                                                padding: 11px 15px;
                                                color: #555;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                line-height: 1;
                                                border-radius: 4px;
                                                transition: all 0.5s;
                                                cursor: pointer;

                                                &:hover {
                                                    background: #FC6736;
                                                    color: #FFF;
                                                    transition: all 0.5s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .amount_input_sec {
                                position: relative;
                                width: 32%;
                                padding: 0 10px;

                                .sec_inner {
                                    position: relative;
                                    width: 100%;

                                    input {
                                        position: relative;
                                        width: 100%;
                                        height: 50px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        padding: 5px 20px;
                                        font-size: 15px;
                                        color: #444;
                                        outline: none;

                                        &:focus,
                                        &:valid {
                                            border: 2px solid #FC6736;
                                            transition: 0.5s;
                                        }
                                    }

                                    span {
                                        position: absolute;
                                        top: 50%;
                                        left: 20px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 15px;
                                        line-height: 1;
                                        color: #555;
                                        pointer-events: none;
                                        transform: translateY(-50%);
                                        transition: 0.5s;
                                    }

                                    input:focus ~ span,
                                    input:valid ~ span {
                                        top: 0%;
                                        left: 10px;
                                        font-size: 13px;
                                        padding: 0px 8px;
                                        background: #FFF;
                                        color: #02C0FF;
                                        font-weight: 500;
                                        transition: 0.5s;
                                    }
                                }
                            }

                            .add_btn {
                                position: relative;
                                width: 28%;
                                padding: 0 10px;

                                a {
                                    position: relative;
                                    width: 100%;
                                    height: 50px;
                                    background: #02C0FF;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 6px;
                                    font-size: 17px;
                                    color: #FFF;
                                    cursor: pointer;
                                    opacity: 0.4;
                                    pointer-events: none;

                                    &.active {
                                        opacity: 1;
                                        pointer-events: initial;
                                    }

                                    i {
                                        margin-right: 6px;
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }

                    .theater_commission_sec {
                        position: relative;
                        margin-top: 25px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        h5 {
                            position: relative;
                            font-size: 16px;
                            padding: 0 10px;
                            font-weight: 600;
                            color: #000;
                        }

                        .input_sec {
                            position: relative;
                            width: 100%;
                            margin-top: 15px;
                            display: flex;
                            flex-wrap: wrap;

                            .select_sec {
                                position: relative;
                                width: 50%;
                                padding: 0 10px;
                                margin-bottom: 20px;

                                &.resize {
                                    width: 40%;
                                }

                                .sec_inner {
                                    position: relative;
                                    width: 100%;

                                    input {
                                        display: none;
                                    }

                                    .select_btn {
                                        position: relative;
                                        width: 100%;
                                        height: 50px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 5px 20px;
                                        font-family: 'Poppins', sans-serif;
                                        cursor: pointer;

                                        p {
                                            position: relative;
                                            padding-right: 10px;
                                            width: calc(100% - 15px);
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            font-size: 15px;
                                            color: #444;
                                            transition: all 0.5s;
                                        }

                                        i{
                                            position: relative;
                                            font-size: 16px;
                                            color: #444;
                                            transition: all 0.5s;

                                            &.active {
                                                transform: rotate(-180deg);
                                                transition: all 0.5s;
                                            }
                                        }

                                        span {
                                            position: absolute;
                                            top: 50%;
                                            left: 20px;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 15px;
                                            line-height: 1;
                                            color: #555;
                                            pointer-events: none;
                                            transform: translateY(-50%);
                                            transition: 0.5s;
                                        }
                                    }

                                    input:focus ~ .select_btn,
                                    input:valid ~ .select_btn {
                                        border: 2px solid #FC6736;
                                    }

                                    input:focus ~ .select_btn span,
                                    input:valid ~ .select_btn span {
                                        top: 0%;
                                        left: 10px;
                                        font-size: 13px;
                                        padding: 0px 8px;
                                        background: #FFF;
                                        color: #02C0FF;
                                        font-weight: 500;
                                        transition: 0.5s;
                                    }

                                    .dropdown {
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        width: 100%;
                                        background: #FFF;
                                        z-index: 50;
                                        border-radius: 5px;
                                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                                        max-height: 0;
                                        overflow: hidden;
                                        transition: all 0.6s;

                                        &.active {
                                            max-height: 130px;
                                            transition: all 0.6s;
                                        }

                                        ul {
                                            position: relative;
                                            width: 100%;
                                            padding: 10px 10px;
                                            max-height: 128px;
                                            overflow: auto;

                                            li {
                                                position: relative;
                                                list-style: none;
                                                padding: 11px 15px;
                                                color: #555;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                line-height: 1;
                                                border-radius: 4px;
                                                transition: all 0.5s;
                                                cursor: pointer;

                                                &:hover {
                                                    background: #FC6736;
                                                    color: #FFF;
                                                    transition: all 0.5s;
                                                }

                                                &.disable {
                                                    color: rgb(170, 170, 170);
                                                    pointer-events: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .amount_input_sec {
                                position: relative;
                                width: 32%;
                                padding: 0 10px;
                                margin-bottom: 20px;

                                .sec_inner {
                                    position: relative;
                                    width: 100%;

                                    input {
                                        position: relative;
                                        width: 100%;
                                        height: 50px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        padding: 5px 20px;
                                        font-size: 15px;
                                        color: #444;
                                        outline: none;

                                        &:focus,
                                        &:valid {
                                            border: 2px solid #FC6736;
                                            transition: 0.5s;
                                        }
                                    }

                                    span {
                                        position: absolute;
                                        top: 50%;
                                        left: 20px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 15px;
                                        line-height: 1;
                                        color: #555;
                                        pointer-events: none;
                                        transform: translateY(-50%);
                                        transition: 0.5s;
                                    }

                                    input:focus ~ span,
                                    input:valid ~ span {
                                        top: 0%;
                                        left: 10px;
                                        font-size: 13px;
                                        padding: 0px 8px;
                                        background: #FFF;
                                        color: #02C0FF;
                                        font-weight: 500;
                                        transition: 0.5s;
                                    }
                                }
                            }

                            .add_btn {
                                position: relative;
                                width: 28%;
                                padding: 0 10px;
                                margin-bottom: 20px;

                                a {
                                    position: relative;
                                    width: 100%;
                                    height: 50px;
                                    background: #02C0FF;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 6px;
                                    font-size: 17px;
                                    color: #FFF;
                                    cursor: pointer;
                                    opacity: 0.4;
                                    pointer-events: none;

                                    &.active {
                                        opacity: 1;
                                        pointer-events: initial;
                                    }

                                    i {
                                        margin-right: 6px;
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .block_permission_sec {
                    position: relative;
                    margin-top: 20px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .sec_heading {
                        position: relative;
                        width: 100%;
                        padding: 0 10px;

                        h4 {
                            position: relative;
                            width: max-content;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 19px;
                            font-weight: 600;
                            color: #964B00;
                            padding-bottom: 2px;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 70%;
                                height: 2px;
                                border-radius: 20px;
                                background: #888;
                            }
                        }
                    }

                    .input_sec {
                        position: relative;
                        width: 100%;
                        margin-top: 20px;
                        display: flex;
                        flex-wrap: wrap;

                        .select_sec {
                            position: relative;
                            width: 50%;
                            padding: 0 10px;
                            margin-bottom: 20px;

                            &.resize {
                                width: 40%;
                            }

                            &.quarter {
                                width: 35%;
                            }

                            .sec_inner {
                                position: relative;
                                width: 100%;

                                input {
                                    display: none;
                                }

                                .select_btn {
                                    position: relative;
                                    width: 100%;
                                    height: 50px;
                                    border: 2px solid #E1E0EA;
                                    border-radius: 6px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 5px 20px;
                                    font-family: 'Poppins', sans-serif;
                                    cursor: pointer;

                                    p {
                                        position: relative;
                                        padding-right: 10px;
                                        width: calc(100% - 15px);
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 15px;
                                        color: #444;
                                        transition: all 0.5s;
                                    }

                                    i{
                                        position: relative;
                                        font-size: 16px;
                                        color: #444;
                                        transition: all 0.5s;

                                        &.active {
                                            transform: rotate(-180deg);
                                            transition: all 0.5s;
                                        }
                                    }

                                    span {
                                        position: absolute;
                                        top: 50%;
                                        left: 20px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 15px;
                                        line-height: 1;
                                        color: #555;
                                        pointer-events: none;
                                        transform: translateY(-50%);
                                        transition: 0.5s;
                                    }
                                }

                                input:focus ~ .select_btn,
                                input:valid ~ .select_btn {
                                    border: 2px solid #FC6736;
                                }

                                input:focus ~ .select_btn span,
                                input:valid ~ .select_btn span {
                                    top: 0%;
                                    left: 10px;
                                    font-size: 13px;
                                    padding: 0px 8px;
                                    background: #FFF;
                                    color: #02C0FF;
                                    font-weight: 500;
                                    transition: 0.5s;
                                }

                                .dropdown {
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    width: 100%;
                                    background: #FFF;
                                    z-index: 50;
                                    border-radius: 5px;
                                    box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                                    max-height: 0;
                                    overflow: hidden;
                                    transition: all 0.6s;

                                    &.active {
                                        max-height: 130px;
                                        transition: all 0.6s;
                                    }

                                    ul {
                                        position: relative;
                                        width: 100%;
                                        padding: 10px 10px;
                                        max-height: 128px;
                                        overflow: auto;

                                        li {
                                            position: relative;
                                            list-style: none;
                                            padding: 11px 15px;
                                            color: #555;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            line-height: 1;
                                            border-radius: 4px;
                                            transition: all 0.5s;
                                            cursor: pointer;

                                            &:hover {
                                                background: #FC6736;
                                                color: #FFF;
                                                transition: all 0.5s;
                                            }

                                            &.disable {
                                                color: rgb(170, 170, 170);
                                                pointer-events: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .add_btn {
                            position: relative;
                            width: 15%;
                            padding: 0 10px;
                            margin-bottom: 20px;

                            a {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                background: #02C0FF;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 6px;
                                font-size: 17px;
                                color: #FFF;
                                cursor: pointer;
                                opacity: 0.4;
                                pointer-events: none;

                                &.active {
                                    opacity: 1;
                                    pointer-events: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;