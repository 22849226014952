import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "../Pages/Login";

import PaneLayout from "../Layouts/Panel-Layout";
import Dashboard from "../Pages/Dashboard";
import TheaterCommissionSlab from "../Pages/TheaterCommissionRangeSlab";
import Users from "../Pages/Users";
import Theaters from "../Pages/Theaters";
import TheaterRequested from "../Pages/Theaters-Requested";
import TheaterListed from "../Pages/Theater-Listed";
import Movies from "../Pages/Movies";
import TodayShowing from "../Pages/TodayShowing";
import RequestedMovies from "../Pages/ReqMovies";
import AddMovie from "../Pages/AddMovie";
import Review from "../Pages/Reviews";





function Router() {
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="panel" element={<PaneLayout />}>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="dashboard" element={<Dashboard />}  />
                        <Route path="commission-slabs" element={<TheaterCommissionSlab />}  />
                        <Route path="users" element={<Users />}  />
                        <Route path="theaters-registered" element={<Theaters />}  />
                        <Route path="theaters-requested" element={<TheaterRequested />}  />
                        <Route path="theaters-listed" element={<TheaterListed />}  />
                        <Route path="movies" element={<Movies />}  />
                        <Route path="today-showing" element={<TodayShowing />}  />
                        <Route path="requested-movies" element={<RequestedMovies />}  />
                        <Route path="add-movie" element={<AddMovie />}  />
                        <Route path="reviews" element={<Review />}  />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}


export default Router;