import { useEffect, useState } from "react";
import { CommissionRangeSlabWrapper } from "../Styles/Theater-Style";
import axios from "axios";
import { Add_Theater_Commission_Slab_URL, Fetch_Theater_Commission_Slab_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';


function TheaterCommissionSlab() {

    const [reload, setReload] = useState(false);
    const [slabMin, setSlabMin] = useState('');
    const [slabMax, setSlabMax] = useState('');
    const [slabs, setSlabs] = useState([]);


    useEffect(() => {
        axios.get(Fetch_Theater_Commission_Slab_URL)
        .then(res=> {
            console.log(res);
            setSlabs(res.data.slabs);
            setReload(false);
        })
        .catch(err => {
            console.log(err);
        })
    }, [reload]);

    const handleAddRangeSlab = () => {
        const slab = `${slabMin} to ${slabMax}`;

        const input = {
            slab: slab
        }

        axios.post(Add_Theater_Commission_Slab_URL, input, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            toast.success('Commission slab created successfully.', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setSlabMin('');
            setSlabMax('');
            setReload(true);
        })
        .catch(err => {
            console.log(err);
            toast.error('Something Went Wrong.', {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setReload(true);
        })
    }

    return(
        <>
            <CommissionRangeSlabWrapper>
                <div className="create_section">
                    <div className="page_heading">
                        <h3>Create Commission Range Slabs for Theaters :~</h3>
                    </div>
                    <div className="form_sec">
                        <p>Slab range</p>
                        <div className="input_sec">
                            <input type="text" value={slabMin} onChange={(e) => setSlabMin(e.target.value)} required />
                            <span>TO</span>
                            <input type="text" value={slabMax} onChange={(e) => setSlabMax(e.target.value)} required />
                            <button className={slabMin && slabMax ? 'active' : ''} onClick={handleAddRangeSlab}><i className="fa-solid fa-plus"></i>Add</button>
                        </div>
                    </div>
                </div>
                <div className="show_section">
                    <div className="page_heading">
                        <h3>Created Range Slabs :~</h3>
                    </div>
                    <div className="slab_sec">
                        {
                            slabs.length > 0 ?
                            slabs.map((slab, i) =>
                                <>
                                    <li>[ <p>{slab.slabs}</p> ]</li>
                                    <span>|</span>
                                </>
                            )
                            :
                            <div className="empty_message">
                                <p>No range slab has been added yet.</p>
                            </div>
                        }
                    </div>
                </div>
            </CommissionRangeSlabWrapper>
        </>
    );
}


export default TheaterCommissionSlab;