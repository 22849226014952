import { useEffect, useState } from "react";
import { ModalTheaterEditBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Add_Theater_Commissions_URL, Add_Update_Block_Permission_URL, Admin_Commission_Add_URL, Fetch_Admin_Commissions_URL, Fetch_Screen_Sections_URL, Fetch_Theater_Commission_Slab_URL, Fetch_Theater_Commissions_URL, Fetch_Theater_Screens_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ModalTheaterEdit({theaterEditModalShow, setTheaterEditModalShow, theaterName, setTheaterName}) {

    const rangeslabs = ['0 to 100','100 to 129','130 to 179','180 to 199','200 to 249','250 to 299','300 to 349','350 to 399','400 to 449','450 to 499','500 to 549','550 to 599','600 to 699','700 to 749','750 to 799','800 to 849','850 to 899','900 to 950','Above 951'];
    const theaterCommissionTypes = ['Single Commission', 'Multiple Commissions'];
    const blockTypes = ['Screen Wise', 'Section Wise'];
    const blockPermissions = ['5', '10', '15', '20', '25'];
    const [range, setRange] = useState('');
    const [rangeDropdown, setRangeDropdown] = useState(false);
    const [amount, setAmount] = useState('');
    const [adminCommissionSettled, setAdminCommissionSettled] = useState(false);
    const [theaterCommissionSettled, setTheaterCommissionSettled] = useState(false);
    const [multiCommission, setMultiCommission] = useState(false);
    const [commissionTypeDropdown, setCommissionTypeDropdown] = useState(false);
    const [commissionSlabDropdown, setCommissionSlabDropdown] = useState(false);
    const [selectedCommissionType, setSelectedCommissionType] = useState('');
    const [theaterCommissionSlabs, setTheaterCommissionSlabs] = useState([]);
    const [selectedTheaterCommissionSlab, setSelectedTheaterCommissionSlab] = useState('');
    const [commissionAmount, setCommissionAmount] = useState('');
    const [blockTypeDropdown, setBlockTypeDropdown] = useState(false);
    const [selectedBlockType, setSelectedBlockType] = useState('');
    const [screenSelection, setScreenSelection] = useState(false);
    const [screens, setScreens] = useState([]);
    const [selectedScreen, setSelectedScreen] = useState('');
    const [selectedScreenId, setSelectedScreenId] = useState('');
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [sectionsShow, setSectionsShow] = useState(false);
    const [screenSections, setScreenSections] = useState([]);
    const [selectedScreenSection, setSelectedScreenSection] = useState('');
    const [selectedScreenSectionName, setSelectedScreenSectionName] = useState('');
    const [sectionDropdown, setSectionDropdown] = useState(false);
    const [permissionShow, setPermissionShow] = useState(false);
    const [permissionDropdown, setPermissionDropdown] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('');
    
    const handleRangeDropdown = () => {
        setRangeDropdown(!rangeDropdown);
    };

    useEffect(() => {
        axios.get(`${Fetch_Admin_Commissions_URL}?theater_name=${theaterName}&&range=${range}`)
        .then(res => {
            // console.log(res);
            setAdminCommissionSettled(true);
            setAmount(res.data.commission);
        })
        .catch(err => {
            // console.log(err);
            setAdminCommissionSettled(false);
            setAmount('');
        })
    }, [range]);

    const handleAdminCommission = (e) => {
        e.preventDefault();
        
        const inputs = {
            theater: theaterName,
            rangleSlab: range,
            amount: amount,
        }

        axios.post(Admin_Commission_Add_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            // console.log(res);
            if(res.data.status == "200") {
                toast.success(`Admin commission against ${range} is settled.`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setRange('');
                setAmount('');
            }
        })
        .catch(err => {
            // console.log(err);
        })
    };

    useEffect(() => {
        if(selectedCommissionType == "Multiple Commissions") {
            setMultiCommission(true);
            axios.get(Fetch_Theater_Commission_Slab_URL)
            .then(res => {
                // console.log(res);
                setTheaterCommissionSlabs(res.data.slabs);
            })
            .catch(err => {
                // console.log(err);
                setTheaterCommissionSlabs([]);
            })
        } else {
            setMultiCommission(false);
            setTheaterCommissionSlabs([]);
        }
    }, [selectedCommissionType]);

    useEffect(() => {
        if(selectedCommissionType == "Single Commission") {
            axios.get(`${Fetch_Theater_Commissions_URL}?commission_type=${selectedCommissionType}&&theater_name=${theaterName}`)
            .then(res => {
                console.log(res);
                setCommissionAmount(res.data.commission);
                setTheaterCommissionSettled(true);
            })
            .catch(err => {
                // console.log(err);
                setTheaterCommissionSettled(false);
            })
        } else if(selectedCommissionType == "Multiple Commissions") {
            axios.get(`${Fetch_Theater_Commissions_URL}?commission_type=${selectedCommissionType}&&theater_name=${theaterName}&&range_slab=${selectedTheaterCommissionSlab}`)
            .then(res => {
                // console.log(res);
                setCommissionAmount(res.data.commission);
                setTheaterCommissionSettled(true);
            })
            .catch(err => {
                // console.log(err);
                setTheaterCommissionSettled(false);
            })
        }
    }, [selectedCommissionType, selectedTheaterCommissionSlab]);

    useEffect(() => {
        axios.get(`${Fetch_Theater_Screens_URL}?theater_name=${theaterName}`)
        .then(res => {
            console.log(res);
            setScreens(res.data.screens);
        })
        .catch(err => {
            // console.log(err);
            setScreens([]);
        })
    }, [theaterName]);

    const handleCommissionTypeDropdown = () => {
        setCommissionTypeDropdown(!commissionTypeDropdown);
    };

    const handleCommissionSlabDropdown = () => {
        setCommissionSlabDropdown(!commissionSlabDropdown);
    };

    const handleBlockTypeDropdown = () => {
        setBlockTypeDropdown(!blockTypeDropdown);
        setScreenDropdown(false);
        setSectionDropdown(false);
        setPermissionDropdown(false);
    };

    const handleTheaterCommission = (e) => {
        e.preventDefault();

        if(selectedCommissionType == "Single Commission") {
            const inputs = {
                theater_name: theaterName,
                commission_type: selectedCommissionType,
                commission: commissionAmount
            };
    
            axios.post(Add_Theater_Commissions_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                // console.log(res);
                toast.success('Commission settled.', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSelectedCommissionType('');
                setCommissionAmount('');
            })
            .catch(err => {
                // console.log(err);
            })
        } else if(selectedCommissionType == "Multiple Commissions") {

            const inputs = {
                theater_name: theaterName,
                commission_type: selectedCommissionType,
                range_slab: selectedTheaterCommissionSlab,
                commission: commissionAmount
            };
            axios.post(Add_Theater_Commissions_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                toast.success('Commission settled.', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSelectedCommissionType('');
                setCommissionAmount('');
            })
            .catch(err => {
                console.log(err);
            })
        }
    };
 
    const handleScreensDropdown = () => {
        setScreenDropdown(!screenDropdown);
        setBlockTypeDropdown(false);
        setSectionDropdown(false);
        setPermissionDropdown(false);
    };

    const handleScreenSelection = (id, screen) => {
        setSelectedScreen(screen); 
        setSelectedScreenId(id);
        setScreenDropdown(false);
        setScreenSelection(true);
        setSelectedScreenSection('');
        setSelectedPermission('');
    };

    const handleBlockTypeSelect = (blockType) => {
        if(blockType == "Section Wise") {
            axios.get(`${Fetch_Screen_Sections_URL}?screen=${selectedScreen}&&screen_id=${selectedScreenId}`)
            .then(res => {
                // console.log(res);
                setScreenSections(res.data.sections);
                setSectionsShow(true);
            })
            .catch(err => {
                // console.log(err);
                setScreenSections([]);
            })
        } else {
            setPermissionShow(true);
            setSectionsShow(false);
        }
        setSelectedBlockType(blockType); 
        setBlockTypeDropdown(false);
        setSelectedScreenSection('');
        setSelectedPermission('');
    };

    const handleSectionDropdown = () => {
        setSectionDropdown(!sectionDropdown);
        setBlockTypeDropdown(false);
        setScreenDropdown(false);
        setPermissionDropdown(false);
    };

    const handleSectionSelection = (sectionName, section) => {
        setSelectedScreenSection(section);
        setSelectedScreenSectionName(sectionName);
        setSelectedPermission('');
        setSectionDropdown(false);
        setPermissionShow(true);
    };

    const handlePermissionDropdown = () => {
        setPermissionDropdown(!permissionDropdown);
        setSectionDropdown(false);
        setBlockTypeDropdown(false);
        setScreenDropdown(false);
    };

    const handlePermissionSelection = (permission) => {
        setSelectedPermission(permission);
        setPermissionDropdown(false);
    };

    const handleBlockPermission = () => {
        if(selectedBlockType == "Screen Wise") {
            const inputs = {
                theater_name: theaterName,
                screen: selectedScreen,
                screen_id: selectedScreenId,
                block_type: selectedBlockType,
                permission: selectedPermission
            };

            axios.post(Add_Update_Block_Permission_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                toast.success(`Block permission against ${selectedScreen} is settled.`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSelectedScreen('');
                setSelectedScreenId('');
                setSelectedBlockType('');
                setSelectedPermission('');
            })
            .catch(err => {
                console.log(err);
                toast.error('Something went wrong.', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
        } else if(selectedBlockType == "Section Wise") {
            const inputs = {
                theater_name: theaterName,
                screen: selectedScreen,
                screen_id: selectedScreenId,
                block_type: selectedBlockType,
                permission: selectedPermission,
                section: selectedScreenSection,
                section_name: selectedScreenSectionName
            };

            axios.post(Add_Update_Block_Permission_URL, inputs, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                toast.success(`Block permission against ${selectedScreenSection} is settled.`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSelectedScreenSection('');
                setSelectedScreenSectionName('');
                setSelectedPermission('');
            })
            .catch(err => {
                console.log(err);
                toast.error('Something went wrong.', {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
        }
    };

    function closeModal() {
        setTheaterEditModalShow(false);
        setAmount('');
        setTheaterName('');
        setSelectedBlockType('');
        setSelectedScreen('');
        setSelectedScreenSection('');
        setSelectedPermission('');
    };


    return(
        <>
            <ModalTheaterEditBox className={theaterEditModalShow ? 'active' : ''}>
                <div className={`edit_box ${theaterEditModalShow ? 'active' : ''}`}>
                    <div className="close_btn">
                        <a onClick={closeModal}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_inner">
                        <div className="heading">
                            <h3><span>Edit</span> {theaterName}</h3>
                        </div>
                        <div className="form_sec">
                            <div className="commission_settlement_sec">
                                <div className="sec_heading">
                                    <h4>Commission Settlement</h4>
                                </div>
                                <div className="admin_commission_sec">
                                    <h5>Admin Commissions :</h5>
                                    <div className="input_sec">
                                        <div className="select_sec">
                                            <div className="sec_inner">
                                                <input type="text" name="slab_range" value={range} required />
                                                <div className="select_btn" onClick={handleRangeDropdown}>
                                                    <p>{range}</p>
                                                    <i class={`fa-solid fa-angle-down ${rangeDropdown? 'active':''}`}></i>
                                                    <span>Range Slab</span>
                                                </div>
                                                <div className={`dropdown ${rangeDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            rangeslabs.map((rangeslab, i) => 
                                                                <li key={i}
                                                                    onClick={() => {
                                                                        setRange(rangeslab)
                                                                        setRangeDropdown(false)
                                                                    }}
                                                                >{rangeslab}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="amount_input_sec">
                                            <div className="sec_inner">
                                                <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                                <span>Amount</span>
                                            </div>
                                        </div>
                                        <div className="add_btn">
                                            <a className={amount ? 'active' : ''} onClick={handleAdminCommission}><i className={`fa-solid ${adminCommissionSettled ? 'fa-pen-to-square' : 'fa-plus'}`}></i> {adminCommissionSettled ? 'Update' : 'Add'}</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="theater_commission_sec">
                                    <h5>Theater Commissions :</h5>
                                    <div className="input_sec">
                                        <div className={`select_sec ${!multiCommission ? 'resize' : ''}`}>
                                            <div className="sec_inner">
                                                <input type="text" name="commission_type" value={selectedCommissionType} required />
                                                <div className="select_btn" onClick={handleCommissionTypeDropdown}>
                                                    <p>{selectedCommissionType}</p>
                                                    <i class={`fa-solid fa-angle-down ${commissionTypeDropdown? 'active':''}`}></i>
                                                    <span>Commission Type</span>
                                                </div>
                                                <div className={`dropdown ${commissionTypeDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            theaterCommissionTypes.map((commissionType, i) => 
                                                                <li key={i}
                                                                    onClick={() => {
                                                                        setSelectedCommissionType(commissionType) 
                                                                        setCommissionTypeDropdown(false)
                                                                        setSelectedTheaterCommissionSlab('')
                                                                        setCommissionAmount('')
                                                                    }}
                                                                >{commissionType}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            multiCommission &&
                                            <div className="select_sec">
                                                <div className="sec_inner">
                                                    <input type="text" name="theater_commission_slab" value={selectedTheaterCommissionSlab} required />
                                                    <div className="select_btn" onClick={handleCommissionSlabDropdown}>
                                                        <p>{selectedTheaterCommissionSlab}</p>
                                                        <i class={`fa-solid fa-angle-down ${commissionSlabDropdown? 'active':''}`}></i>
                                                        <span>Select Range</span>
                                                    </div>
                                                    <div className={`dropdown ${commissionSlabDropdown? 'active':''}`}>
                                                        <ul>
                                                            {
                                                                theaterCommissionSlabs.length > 0 ? 
                                                                theaterCommissionSlabs.map((commissionSlabs, i) => 
                                                                    <li key={i}
                                                                        onClick={() => {
                                                                            setSelectedTheaterCommissionSlab(commissionSlabs.slabs)
                                                                            setCommissionSlabDropdown(false)
                                                                            setCommissionAmount('')
                                                                        }}
                                                                    >{commissionSlabs.slabs}</li>
                                                                )
                                                                :
                                                                <li className="disable">No Slab Created</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="amount_input_sec">
                                            <div className="sec_inner">
                                                <input type="text" value={commissionAmount} onChange={(e) => setCommissionAmount(e.target.value)} required />
                                                <span>Amount</span>
                                            </div>
                                        </div>
                                        <div className="add_btn">
                                            <a className={commissionAmount ? 'active' : ''} onClick={handleTheaterCommission}><i className={`fa-solid ${theaterCommissionSettled ? 'fa-pen-to-square' : 'fa-plus'}`}></i> {theaterCommissionSettled ? 'Update' : 'Add'}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block_permission_sec">
                                <div className="sec_heading">
                                    <h4>Seat Block Permission</h4>
                                </div>
                                <div className="input_sec">
                                    <div className="select_sec">
                                        <div className="sec_inner">
                                            <input type="text" name="screen" value={selectedScreen} required />
                                            <div className="select_btn" onClick={handleScreensDropdown}>
                                                <p>{selectedScreen}</p>
                                                <i class={`fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                                <span>Select Screen</span>
                                            </div>
                                            <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                                <ul>
                                                    {
                                                        screens.map((screen, i) => 
                                                            <li key={i}
                                                                onClick={() => {
                                                                    handleScreenSelection(screen.screen_id, screen.screen)
                                                                }}
                                                            >{screen.screen}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        screenSelection && 
                                        <div className="select_sec">
                                            <div className="sec_inner">
                                                <input type="text" name="block_type" value={selectedBlockType} required />
                                                <div className="select_btn" onClick={handleBlockTypeDropdown}>
                                                    <p>{selectedBlockType}</p>
                                                    <i class={`fa-solid fa-angle-down ${blockTypeDropdown? 'active':''}`}></i>
                                                    <span>Block Type</span>
                                                </div>
                                                <div className={`dropdown ${blockTypeDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            blockTypes.map((blockType, i) => 
                                                                <li key={i}
                                                                    onClick={() => {
                                                                        handleBlockTypeSelect(blockType)
                                                                    }}
                                                                >{blockType}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        sectionsShow && 
                                        <div className="select_sec">
                                            <div className="sec_inner">
                                                <input type="text" name="section" value={selectedScreenSection} required />
                                                <div className="select_btn" onClick={handleSectionDropdown}>
                                                    <p>{selectedScreenSection}</p>
                                                    <i class={`fa-solid fa-angle-down ${sectionDropdown? 'active':''}`}></i>
                                                    <span>Select Section</span>
                                                </div>
                                                <div className={`dropdown ${sectionDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            screenSections.map((section, i) => 
                                                                <li key={i}
                                                                    onClick={() => {
                                                                        handleSectionSelection(section.sec_name, section.section)
                                                                    }}
                                                                >{`${section.section} [ ${section.sec_name} ]`}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        permissionShow && 
                                        <div className="select_sec quarter">
                                            <div className="sec_inner">
                                                <input type="text" name="section" value={selectedPermission} required />
                                                <div className="select_btn" onClick={handlePermissionDropdown}>
                                                    <p>{selectedPermission}</p>
                                                    <i class={`fa-solid fa-angle-down ${permissionDropdown? 'active':''}`}></i>
                                                    <span>Permission(%)</span>
                                                </div>
                                                <div className={`dropdown ${permissionDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            blockPermissions.map((permission, i) => 
                                                                <li key={i}
                                                                    onClick={() => {
                                                                        handlePermissionSelection(permission)
                                                                    }}
                                                                >{`${permission}%`}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        permissionShow && 
                                        <div className="add_btn">
                                            <a className={selectedPermission ? 'active' : ''} onClick={handleBlockPermission}><i className="fa-solid fa-plus"></i></a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalTheaterEditBox>
        </>
    );
}


export default ModalTheaterEdit;